<template>
    <v-container>
        <h3>{{$t('message.register-merchant-title')}}</h3>
        <v-card>
            <v-col class="pr-2 pb-2" cols="12">
                <form @submit.prevent="submit">
                    <v-row>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.merchant-name')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.merchant_name"
                            :error-messages="merchant_nameErrors"
                            @input="$v.applyData.merchant_name.$touch()"
                            @blur="$v.applyData.merchant_name.$touch()"
                            outlined
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.email')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.email"
                            :error-messages="emailErrors"
                            @input="$v.applyData.email.$touch()"
                            @blur="$v.applyData.email.$touch()"
                            outlined
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.password')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.password"
                            name="password"
                            autocomplete="on"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                            :error-messages="passwordErrors"
                            @input="$v.applyData.password.$touch()"
                            @blur="$v.applyData.password.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.phone')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.phone"
                            :error-messages="phoneErrors"
                            @input="$v.applyData.phone.$touch()"
                            @blur="$v.applyData.phone.$touch()"
                            outlined 
                            required
                            type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.company-name')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.company_name"
                            :error-messages="company_nameErrors"
                            @input="$v.applyData.company_name.$touch()"
                            @blur="$v.applyData.company_name.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.pic-name')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.pic_name"
                            :error-messages="pic_nameErrors"
                            @input="$v.applyData.pic_name.$touch()"
                            @blur="$v.applyData.pic_name.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.postal-code')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-row align="center">
                                <v-col cols="8">
                                    <v-text-field
                                    v-model="applyData.postalcode"
                                    :error-messages="postalcodeErrors"
                                    @input="$v.applyData.postalcode.$touch()"
                                    @blur="$v.applyData.postalcode.$touch()"
                                    outlined 
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col class="mb-8">
                                    <v-btn
                                    depressed
                                    outlined
                                    @click.prevent="getAddress()"
                                    :disabled="isPostal"
                                    >
                                    <span>
                                        {{ $t("message.search") }}
                                    </span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.prefecture')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.prefecture"
                            :error-messages="prefectureErrors"
                            @input="$v.applyData.prefecture.$touch()"
                            @blur="$v.applyData.prefecture.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.city')}}</span>
                            
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.city"
                            :error-messages="cityErrors"
                            @input="$v.applyData.city.$touch()"
                            @blur="$v.applyData.city.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.district')}}</span>
                            
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.district"
                            :error-messages="districtErrors"
                            @input="$v.applyData.district.$touch()"
                            @blur="$v.applyData.district.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.address')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-text-field
                            v-model="applyData.address"
                            :error-messages="addressErrors"
                            @input="$v.applyData.address.$touch()"
                            @blur="$v.applyData.address.$touch()"
                            outlined 
                            required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" xs="2" class="label-class">
                            <span class="label">{{$t('message.image')}}</span>
                        </v-col>
                        <v-col cols="12" md="10" xs="2" class="input-class">
                            <v-file-input
                            v-model="applyData.images"
                            outlined
                            @change="upload"
                            required
                            accept="image/"
                            type="file"
                            ref="myfile"
                            id="file-input"
                            ></v-file-input>
                                <v-row
                                    v-if="isLoadingImg"
                                    class="fill-height ma-0"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="blue"
                                    ></v-progress-circular>
                                </v-row>

                            <v-img
                                :lazy-src="this.applyData.image_url"
                                :src="this.applyData.image_url"
                                max-height="150"
                                max-width="180px"
                            >
                            </v-img>
                        </v-col>
                    </v-row>
                    <div class="d-flex flex-row-reverse mt-3 me-2" tile>
                        <div class="m-2">
                        <v-btn color="primary" type="submit" @click.prevent="submit">
                            <div v-if="isLoading" style="margin-right:10px">
                            <v-progress-circular
                                indeterminate
                                color="white"
                                size="11"
                                left
                            ></v-progress-circular>
                            </div>
                            {{$t('message.submit-button')}}
                        </v-btn>
                        </div>
                        <div class="d-flex flex-row-reverse me-0" tile>
                        <div class="m-2">
                            <v-btn color="grey" @click.prevent="$router.go(-1)">
                            {{$t('message.back-button')}}
                            </v-btn>
                        </div>
                        </div>
                    </div>
                </form>
            </v-col>
        </v-card>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { 
    required, 
    email,
    maxLength,
    minLength,
    numeric
} from "vuelidate/lib/validators";
import { mdiMagnify } from "@mdi/js";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    name: "ApplyingMerchant",
    mixins: [validationMixin],
    validations: {
        applyData: {
            merchant_name: { required, maxLength: maxLength(50) },
            email: { required, email },
            password: { required, minLength: minLength(6), maxLength: maxLength(30) },
            phone: { required, numeric: numeric },
            company_name: { required },
            pic_name: { required },
            address: { required },
            prefecture: { required },
            postalcode: { required, maxLength: maxLength(8) },
            city: { required },
            district: { required },
            // fee: { required, numeric: numeric },
            // images: { required },
            // image_url: { required },
        },
    },
    data: () => ({
        isLoading: false,
        isLoadingImg: false,
        showPassword: false,
        showConPassword: false,
        isPostal: true,
        applyData: {
            merchant_name: "",
            email: "",
            password: "",
            phone: "",
            company_name: "",
            pic_name: "",
            address: "",
            prefecture: "",
            postalcode: "",
            city: "",
            district: "",
            // fee: "",
            images: null,
            image_url: null
        },
        message: {},
        iconSearch: mdiMagnify,
    }),
    methods: {
        async upload(event) {
            this.isLoadingImg = true
            if(this.applyData.images != null) {
                await this.$store.dispatch('uploadImage_module/upload', { 
                        images: this.applyData.images, 
                        path: 'items'
                    })

                let uploadImgRes = await this.$store.state.uploadImage_module.resLinkImg
                this.applyData.image_url = uploadImgRes.image_url
            }else{
                this.applyData.image_url = null
            }
            this.isLoadingImg = false
        },
        submit() {
            this.$v.$touch();
            this.isLoading = true
            this.message.title = this.$t("message.title-applying-success-message")
            localStorage.setItem('merchant_name', this.applyData.merchant_name)

            let refacPostcode = this.applyData.postalcode
            refacPostcode = refacPostcode.replace("-","")
            let payload = {
                merchant_name: this.applyData.merchant_name,
                email: this.applyData.email,
                password: this.applyData.password,
                phone: this.applyData.phone,
                company_name: this.applyData.company_name,
                pic_name: this.applyData.pic_name,
                address: this.applyData.address,
                prefecture: this.applyData.prefecture,
                postalcode: refacPostcode,
                city: this.applyData.city,
                district: this.applyData.district,
                // fee: this.applyData.fee,
                logo_url: this.applyData.image_url,
                message: {
                    title: this.message.title
                }
            }
            
            this.$store.dispatch('applyingMerchant_module/fetchApplyingMerchant', payload)
            this.isLoading = false
        },
        async getAddress(){
            let postalcode = this.applyData.postalcode
            postalcode = postalcode.replace("-","")
            let resp = await this.$store.dispatch("shop_module/getAddressByPostal", {postal: postalcode})
            if(resp.data.length > 0){
                let respaddr = resp.data[0]
                this.applyData.prefecture = respaddr.pref
                this.applyData.city = respaddr.city
                this.applyData.district = respaddr.town
            }else{
                let message
                if(typeof resp.data.message === 'undefined') message = this.$t("message.postal-code-notfound-message")
                else message = this.$t("message.postal-code-api-error-message")

                this.applyData.prefecture = ""
                this.applyData.city = ""
                this.applyData.district = ""
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                    },
                })
                Toast.fire({
                    icon: 'warning',
                    title: message
                })
            }
        }
    },
    computed: {
        merchant_nameErrors() {
            const errors = [];
            if (!this.$v.applyData.merchant_name.$dirty) return errors;
            !this.$v.applyData.merchant_name.required &&
                errors.push(this.$t("message.err-msg-merchant_name"));
            !this.$v.applyData.merchant_name.maxLength &&
                errors.push(this.$t("message.err-msg-maxLength-merchant_name"));
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.applyData.email.$dirty) return errors;
            !this.$v.applyData.email.email && errors.push(this.$t("message.err-msg-email"));
            !this.$v.applyData.email.required &&
                errors.push(this.$t("message.err-msg-email-required"));
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.applyData.password.$dirty) return errors;
            !this.$v.applyData.password.minLength &&
                errors.push(this.$t("message.err-msg-password-minLength"));
            !this.$v.applyData.password.maxLength &&
                errors.push(this.$t("message.err-msg-password-maxLength"));
            !this.$v.applyData.password.required &&
                errors.push(this.$t("message.err-msg-password"));
            return errors;
        },
        phoneErrors() {
            const errors = [];
            if (!this.$v.applyData.phone.$dirty) return errors;
            !this.$v.applyData.phone.required &&
                errors.push(this.$t("message.err-msg-phone-required"));
            !this.$v.applyData.phone.numeric &&
                errors.push(this.$t("message.err-msg-phone-numeric"));
            return errors;
        },
        company_nameErrors() {
            const errors = [];
            if (!this.$v.applyData.company_name.$dirty) return errors;
            !this.$v.applyData.company_name.required &&
                errors.push(this.$t("message.err-msg-company_name-required"));
            return errors;
        },
        pic_nameErrors() {
            const errors = [];
            if (!this.$v.applyData.pic_name.$dirty) return errors;
            !this.$v.applyData.pic_name.required &&
                errors.push(this.$t("message.err-msg-pic_name-required"));
            return errors;
        },
        addressErrors() {
            const errors = [];
            if (!this.$v.applyData.address.$dirty) return errors;
            !this.$v.applyData.address.required &&
                errors.push(this.$t("message.err-address"));
            return errors;
        },
        postalcodeErrors() {
            const errors = [];
            this.isPostal = false
            if (!this.$v.applyData.postalcode.$dirty) return errors;
            !this.$v.applyData.postalcode.required &&
                errors.push(this.$t("message.err-postal-req"));
            !this.$v.applyData.postalcode.maxLength &&
                errors.push(this.$t("message.err-msg-postal-12digit"))
            // let postcode = this.applyData.postalcode
            // if(postcode != null) {
            //     if(postcode.slice(0,3) != '507') {
            //         errors.push("postal code must be input 507")
            //     }
            // }
            if(errors.length > 0) {
                this.isPostal = true
            }
            return errors;
        },
        prefectureErrors() {
            const errors = [];
            if (!this.$v.applyData.prefecture.$dirty) return errors;
            !this.$v.applyData.prefecture.required &&
                errors.push(this.$t("message.err-prefecture"));
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.applyData.city.$dirty) return errors;
            !this.$v.applyData.city.required &&
                errors.push(this.$t("message.err-city"));
            return errors;
        },
        districtErrors() {
            const errors = [];
            if (!this.$v.applyData.district.$dirty) return errors;
            !this.$v.applyData.district.required &&
                errors.push(this.$t("message.err-district"));
            return errors;
        },
        // imagesErrors() {
        //     const errors = [];
        //     if (!this.$v.applyData.images.$dirty) return errors;
        //     !this.$v.applyData.images.required &&
        //         errors.push(this.$t("message.err-msg-image"));
        //     return errors;
        // },
    },
    created() {

    }
}
</script>

<style>

</style>